import styled from 'styled-components'
import { WrapperProps } from './typed'

export const Wrapper = styled.div<WrapperProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${(props) => (props.isOpen ? 'calc(320px - 32px)' : '72px')};
    height: 100vh;
    padding: ${(props) =>
        props.isOpen ? '32px 12px 24px 12px' : '32px 12px 24px 12px'};
    background: #ffffff;
    border-right: 1px solid #eaecf0;
    overflow: hidden;
    transition: width 0.3s;
`

export const MenuItem = styled.div`
    padding: 8px 12px;
    width: calc(100% - 24px);
    height: calc(40px - 16px);
    background: #ffffff;
    border-radius: 6px;
    margin-top: 4px;
    margin-left: 0;
    cursor: pointer;

    &:hover {
        background: #f9fafb;
    }

    & > svg {
        stroke: #667085;
    }
`

export const NameBlock = styled.div`
    margin: ${(props: { isOpen: boolean }) =>
        props.isOpen ? '0 28px 34px 12px;' : '0 0 34px 0'};
    ${(props: { isOpen: boolean }) => (props.isOpen ? '' : 'display: flex;')};
    ${(props: { isOpen: boolean }) =>
        props.isOpen ? '' : 'justify-content: center;'};
    ${(props: { isOpen: boolean }) =>
        props.isOpen ? '' : 'align-items: center;'};
`
export const IconWrapper = styled.div`
    margin: 0 0 0 0;
`
export const LogoWrapper = styled.div`
    margin: 0 0 0 0;
`

export const MenuSection = styled.div``
