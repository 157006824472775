import {
    IconWrapper,
    LogoWrapper,
    MenuSection,
    NameBlock,
    Wrapper
} from './styled'
import { useEffect, useState } from 'react'
import 'antd/dist/reset.css'
import {
    Col,
    ConfigProvider,
    Divider,
    Menu,
    MenuProps,
    Row,
    Space,
    Typography
} from 'antd'
import { Logo } from './components/Icons/Logo/Logo'
import { IconLogOut } from '@rpt/components-library'
import { Observable } from 'windowed-observable'

export default function Root(props) {
    const [opend, setOpened] = useState(true)
    const [activeMenu, setActiveMenu] = useState<string[]>([
        window.location.pathname.indexOf('/', 1) == -1
            ? window.location.pathname
            : window.location.pathname.slice(
                  0,
                  window.location.pathname.indexOf('/', 1)
              )
    ])

    useEffect(() => {
        window.addEventListener('showSideBar', () => {
            setOpened(true)
        })
        window.addEventListener('hideSideBar', () => {
            setOpened(false)
        })

        const observable = new Observable('showSidebar')
        setOpened(observable.getLastEvent())
        observable.subscribe((value) => {
            setOpened(value)
        })
    }, [])

    type MenuItem = Required<MenuProps>['items'][number]

    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
        danger?: boolean
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
            type,
            danger,
            style: {
                padding: '12px',
                cursor: 'pointer',
                margin: 0,
                width: opend ? 'auto' : 48,
                height: 48
            }
        } as MenuItem
    }

    const items: MenuProps['items'] = []
    const itemsDown: MenuProps['items'] = []

    const apps = props.apps.sort((a, b) => a.uiOrder - b.uiOrder)

    apps.forEach((item) => {
        if (item.uiBottom) {
            itemsDown.push(
                getItem(
                    item.displayName,
                    item.route,
                    <IconWrapper>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            dangerouslySetInnerHTML={{ __html: item.icon }}
                            className={
                                activeMenu[0] == item.route ? 'path-active' : ''
                            }
                        />
                        <span />
                    </IconWrapper>
                )
            )
        } else {
            items.push(
                getItem(
                    item.displayName,
                    item.route,
                    <IconWrapper>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            dangerouslySetInnerHTML={{ __html: item.icon }}
                            className={
                                activeMenu[0] == item.route ? 'path-active' : ''
                            }
                        />
                        <span />
                    </IconWrapper>
                )
            )
        }
    })

    function logout() {
        const event = new CustomEvent('logout')
        window.dispatchEvent(event)
    }

    function onClick(event: any) {
        const customEvent = new CustomEvent('navigateTo', {
            detail: {
                url: event.key
            }
        })
        window.dispatchEvent(customEvent)
    }

    // console.log(props)

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#F9FAFB',
                    fontFamily: 'GT Eesti Pro Display'
                },
                components: {
                    Menu: {
                        colorItemText: '#344054',
                        colorItemTextSelected: '#726CFF',
                        colorItemBgSelected: '#F9FAFB'
                    }
                }
            }}
        >
            <Wrapper isOpen={opend}>
                {/* <Divider height={32} /> */}
                <Space
                    direction="vertical"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: '100%'
                    }}
                >
                    <Space direction="vertical">
                        <LogoWrapper>
                            <Logo isFull={opend}></Logo>
                        </LogoWrapper>
                        {/* <Divider height={24} /> */}
                        <Menu
                            onClick={onClick}
                            style={{
                                width: !opend ? 72 : 254,
                                borderRight: 'none'
                            }}
                            mode="inline"
                            items={items}
                            inlineIndent={12}
                            inlineCollapsed={!opend}
                            selectedKeys={activeMenu}
                            onSelect={(data) => setActiveMenu(data.keyPath)}
                        />
                    </Space>
                    <Menu
                        onClick={onClick}
                        style={{
                            width: !opend ? 72 : 254,
                            borderRight: 'none'
                        }}
                        mode="inline"
                        items={itemsDown}
                        inlineIndent={12}
                        inlineCollapsed={!opend}
                        selectedKeys={activeMenu}
                        onSelect={(data) => setActiveMenu(data.keyPath)}
                    />
                </Space>
                <Col>
                    <Divider type="horizontal" style={{ marginBottom: 40 }} />
                    <NameBlock isOpen={opend}>
                        <Row justify={'space-between'}>
                            {opend && (
                                <Col span={19}>
                                    <Typography.Paragraph ellipsis>
                                        {/* {`${props.userInfo.firstName} ${props.userInfo.lastName}`} */}
                                        {props.userInfo.email.slice(
                                            0,
                                            props.userInfo.email.indexOf('@')
                                        )}
                                    </Typography.Paragraph>
                                </Col>
                            )}
                            <Col offset={2}>
                                <IconLogOut onClick={logout} width={24} />
                            </Col>
                        </Row>
                    </NameBlock>
                </Col>
            </Wrapper>
        </ConfigProvider>
    )
}
