import React from 'react'

export function Logo({ isFull }: { isFull: boolean }) {
    return (
        <svg
            width={isFull ? '142' : '40'}
            height="33"
            viewBox={`0 0 ${isFull ? '142' : '40'} 33`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.5025 7.91501H15.0149C13.6724 7.91501 13.6537 8.42639 13.6537 9.93093V18.7034C13.4509 22.029 15.4489 22.0464 17.1054 21.7854C24.9017 20.5571 26.6072 20.8108 33.6969 21.3103C35.2973 21.4231 35.1068 19.8937 35.1068 18.8585V9.60402C35.1068 8.09948 34.845 7.91501 33.5025 7.91501ZM13.6537 4C10.6394 4 9.95879 5.25005 9.95879 8.62809V12.3806C9.95879 12.3806 9.95879 14.8296 9.95879 15.5923C9.95879 16.0087 9.53065 17.9849 8.82562 19.0192C7.74096 20.3801 5.62588 19.8357 5.57164 20.5571C5.57164 21.9588 10.0188 19.7813 9.95879 21.1019C10.9407 26.3681 0.202573 23.864 2.26343 26.6947C6.43937 29.09 11.7 23.0089 15.8619 25.951C16.0078 27.8035 9.3735 27.1284 11.8529 29.2533C16.1031 31.4871 21.3367 25.6836 24.4145 25.1963C28.069 24.4654 30.9926 25.1963 35.0449 25.5727C38.0105 25.5727 38.5453 24.1561 38.5453 20.6147V8.62809C38.5453 5.14108 37.4271 4 35.0449 4H13.6537Z"
                fill="#FFC300"
            />
            <path
                d="M21.0036 14.4763C21.0036 15.6874 20.0219 16.6691 18.8109 16.6691C17.5999 16.6691 16.6182 15.6874 16.6182 14.4763C16.6182 13.2653 17.5999 12.2836 18.8109 12.2836C20.0219 12.2836 21.0036 13.2653 21.0036 14.4763Z"
                fill="#FFC300"
            />
            <path
                d="M26.8508 14.4763C26.8508 15.6874 25.8691 16.6691 24.6581 16.6691C23.447 16.6691 22.4653 15.6874 22.4653 14.4763C22.4653 13.2653 23.447 12.2836 24.6581 12.2836C25.8691 12.2836 26.8508 13.2653 26.8508 14.4763Z"
                fill="#FFC300"
            />
            <path
                d="M32.6979 14.4763C32.6979 15.6874 31.7162 16.6691 30.5052 16.6691C29.2942 16.6691 28.3125 15.6874 28.3125 14.4763C28.3125 13.2653 29.2942 12.2836 30.5052 12.2836C31.7162 12.2836 32.6979 13.2653 32.6979 14.4763Z"
                fill="#FFC300"
            />
            <path
                d="M53.8588 9.03778H53.7368C51.8817 9.03778 50.5881 9.98229 50.0023 11.6824H49.9534V9.36836H47V20.2774H49.9779V15.8146C49.9779 13.1464 51.1251 12.1547 53.5171 12.1547H53.8588V9.03778Z"
                fill="#181D48"
            />
            <path
                d="M59.5775 20.6316C61.4082 20.6316 62.6286 19.876 63.3364 18.7426H63.3853V20.2774H66.3631V9.36836H63.3853V10.9032H63.3364C62.6286 9.76978 61.4082 9.01417 59.5775 9.01417C56.6241 9.01417 54.1588 11.0921 54.1588 14.8229C54.1588 18.5537 56.6241 20.6316 59.5775 20.6316ZM60.3342 17.8926C58.5279 17.8926 57.2831 16.6647 57.2831 14.8229C57.2831 12.9811 58.5279 11.7532 60.3342 11.7532C62.1404 11.7532 63.3853 12.9811 63.3853 14.8229C63.3853 16.6647 62.1404 17.8926 60.3342 17.8926Z"
                fill="#181D48"
            />
            <path
                d="M68.9535 25H71.9313V18.7426H71.9802C72.688 19.876 73.9084 20.6316 75.7391 20.6316C78.6925 20.6316 81.1578 18.5537 81.1578 14.8229C81.1578 11.0921 78.6925 9.01417 75.7391 9.01417C73.9084 9.01417 72.688 9.76978 71.9802 10.9032H71.9313V9.36836H68.9535V25ZM74.9824 17.8926C73.1762 17.8926 71.9313 16.6647 71.9313 14.8229C71.9313 12.9811 73.1762 11.7532 74.9824 11.7532C76.7887 11.7532 78.0335 12.9811 78.0335 14.8229C78.0335 16.6647 76.7887 17.8926 74.9824 17.8926Z"
                fill="#181D48"
            />
            <path
                d="M83.1124 25H86.0903V18.7426H86.1391C86.8469 19.876 88.0674 20.6316 89.898 20.6316C92.8514 20.6316 95.3167 18.5537 95.3167 14.8229C95.3167 11.0921 92.8514 9.01417 89.898 9.01417C88.0674 9.01417 86.8469 9.76978 86.1391 10.9032H86.0903V9.36836H83.1124V25ZM89.1413 17.8926C87.3351 17.8926 86.0903 16.6647 86.0903 14.8229C86.0903 12.9811 87.3351 11.7532 89.1413 11.7532C90.9476 11.7532 92.1924 12.9811 92.1924 14.8229C92.1924 16.6647 90.9476 17.8926 89.1413 17.8926Z"
                fill="#181D48"
            />
            <path
                d="M102.836 9.01417C99.2484 9.01417 96.5879 11.3991 96.5879 14.8229C96.5879 18.2468 99.2484 20.6316 102.836 20.6316C106.425 20.6316 109.085 18.2468 109.085 14.8229C109.085 11.3991 106.425 9.01417 102.836 9.01417ZM102.836 11.7532C104.667 11.7532 105.985 12.9575 105.985 14.8229C105.985 16.6883 104.667 17.8926 102.836 17.8926C101.006 17.8926 99.6634 16.6883 99.6634 14.8229C99.6634 12.9575 101.006 11.7532 102.836 11.7532Z"
                fill="#181D48"
            />
            <path
                d="M117.884 9.03778H117.762C115.907 9.03778 114.613 9.98229 114.027 11.6824H113.978V9.36836H111.025V20.2774H114.003V15.8146C114.003 13.1464 115.15 12.1547 117.542 12.1547H117.884V9.03778Z"
                fill="#181D48"
            />
            <path
                d="M125.834 11.8005V9.36836H123.516V5H120.513V9.36836H118.439V11.8005H120.513V17.0661C120.513 19.2621 121.783 20.3011 123.906 20.3011H125.224V17.6092H124.712C123.833 17.6092 123.516 17.255 123.516 16.3813V11.8005H125.834Z"
                fill="#181D48"
            />
            <path
                d="M132.751 9.01417C129.163 9.01417 126.503 11.3991 126.503 14.8229C126.503 18.2468 129.163 20.6316 132.751 20.6316C136.339 20.6316 139 18.2468 139 14.8229C139 11.3991 136.339 9.01417 132.751 9.01417ZM132.751 11.7532C134.582 11.7532 135.9 12.9575 135.9 14.8229C135.9 16.6883 134.582 17.8926 132.751 17.8926C130.921 17.8926 129.578 16.6883 129.578 14.8229C129.578 12.9575 130.921 11.7532 132.751 11.7532Z"
                fill="#181D48"
            />
        </svg>
    )
}
